
import { computed, ComputedRef, defineComponent, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ElMessageBox, ElMessage } from 'element-plus';

import { useAdvertisements, useBlocks, useDeleteAdvertisement } from '@/composables/api';
import { AdvertisementIdOption, DEFAULT_PAGE_SIZE, DELETED_SUCCESSFULLY_TEXT, ResponseError, SortType } from '@/services/api';
import { AdvertisementStatus, AdvertisementPosition, Block, AdvertisementPositionType, AdvertisementStatusType, Advertisements } from '@/interfaces';
import { formatLocalTime } from '@/utils/format-time';
import { getStatusTagType } from '@/utils/render';
import SearchBox from '@/components/keyword-searching/Index.vue';
import Filter, { FilterEvent, FilterOption, FilterType } from '@/components/filter/Index.vue';
import { get } from 'lodash';

import permissionUnits from '@/components/permission-units/index.vue';
import { usePage } from '@/composables/page';
import { useKeyword } from '@/composables';

export default defineComponent({
  components: {
    SearchBox,
    Filter,
    permissionUnits
  },
  setup() {
    const { keyword } = useKeyword();
    const status = ref();
    const position = ref();
    const blockIds = ref<number[]>();

    const router = useRouter();
    const route = useRoute();
    const { setPageQuery, page, pageSize } = usePage(1, DEFAULT_PAGE_SIZE);
    const sort = ref<SortType>('desc');
    const { data, isLoading, isFetching, refetch } = useAdvertisements({ page, keyword, pageSize, blockIds, position, status, sort });
    const { isLoading: isDeleting, mutate } = useDeleteAdvertisement();

    const {
      data: blocksRes,
      isLoading: isBlockLoading,
      isFetching: isBlockFetching,
      refetch: blockRefetch
    } = useBlocks();

    const blocks = computed(() => get(blocksRes.value, 'data', []) as Block[]);

    const currentDeletedIndex = ref<number>();
    const deleteAdvertisement = ({ id }: AdvertisementIdOption, index: number) => {
      currentDeletedIndex.value = index;
      ElMessageBox.confirm('是否確認要刪除？', '警告', {
        confirmButtonText: '刪除',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          mutate({ id }, {
            onSuccess() {
              refetch.value();

              ElMessage.success(DELETED_SUCCESSFULLY_TEXT);
            },
            onError(error: ResponseError) {
              ElMessage.error(error.response?.data.message);
            }
          });
        })
        .catch();
    };

    const filterOptions: ComputedRef<FilterOption[]> = computed(() => {
      return [
        {
          type: FilterType.SELECTOR,
          label: '狀態',
          placeholder: '請選擇',
          options: [
            {
              label: '上架中',
              value: AdvertisementStatus.PUBLISHED
            },
            {
              label: '未上架',
              value: AdvertisementStatus.NOT_PUBLISHED
            }
          ]
        },
        {
          type: FilterType.SELECTOR,
          label: '廣告版位',
          placeholder: '請選擇',
          options: [
            {
              label: '場館 Banner',
              value: AdvertisementPosition.BLOCK_BANNER
            },
            {
              label: '場館浮動按鈕',
              value: AdvertisementPosition.FLOAT_BUTTON
            },
            {
              label: '個人中心 Banner',
              value: AdvertisementPosition.PROFILE_BANNER
            },
            {
              label: 'Header 按鈕',
              value: AdvertisementPosition.HEADER_BUTTON
            }
          ]
        },
        {
          type: FilterType.MULTI_SELECTOR,
          label: '上架場館',
          placeholder: '請選擇',
          options: blocks.value.map(({ name, id }) => ({ label: name, value: id }))
        }
      ];
    });

    const handleFilterChange = (event: any) => {
      status.value = event[0];
      position.value = event[1];
      blockIds.value = event[2];
    };

    const getPositionsTranslate = (position: AdvertisementPositionType) => {
      const cases = {
        [AdvertisementPosition.BLOCK_BANNER]: '場館 Banner',
        [AdvertisementPosition.PROFILE_BANNER]: '個人中心 Banner',
        [AdvertisementPosition.FLOAT_BUTTON]: '場館浮動按鈕',
        [AdvertisementPosition.HEADER_BUTTON]: 'Header 按鈕'
      };
      return cases[position] || '未定義';
    };

    const getFormattedBlocksTranslate = (blocks: Block[]) => {
      if (blocks.length === 0) return '';
      const resArr = blocks.map((val, idx, arr) => val.name);
      return resArr.join('、');
    };

    const getStatusTranslate = (status: AdvertisementStatusType) => {
      const cases = {
        [AdvertisementStatus.NOT_PUBLISHED]: '未上架',
        [AdvertisementStatus.PUBLISHED]: '上架中',
        [AdvertisementStatus.ENDED]: '已結束'
      };
      return cases[status];
    };

    const getFormattedPositions = (data: Advertisements) => {
      return `
        ${getPositionsTranslate(data.position)}
        ${(data.position === AdvertisementPosition.BLOCK_BANNER) ? ' - ' : ''}
        ${getFormattedBlocksTranslate(data.blocks || [])}
      `;
    };

    const handleSort = (e) => {
      const { prop, order } = e;

      if (prop === 'id') {
        const sortTranslate = {
          ascending: 'asc',
          descending: 'desc'
        };
        sort.value = sortTranslate[order];
      }
    };

    return {
      page,
      data,
      isLoading,
      isFetching,
      isDeleting,
      keyword,
      deleteAdvertisement,
      currentDeletedIndex,
      formatLocalTime,
      getStatusTagType,
      setPageQuery,
      filterOptions,
      handleFilterChange,
      blocks,
      getStatusTranslate,
      getFormattedPositions,
      handleSort
    };
  }
});
